@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply font-bold;
    @apply text-2xl;
  }

  h2 {
    @apply font-bold;
    @apply text-xl;
  }

  h3 {
    @apply font-bold;
    @apply text-lg;
  }
}

@layer components {
  a, button {
    /* transition */
    @apply transition duration-300 ease-in-out;
  }

  .primary, .btn-primary {
    @apply bg-blue-1 rounded-md py-2 px-4;

    @apply text-sm font-semibold leading-5 text-white;
    @apply border border-blue-1;

    @apply hover:opacity-75;
  }

  .secondary, .btn-secondary {
    @apply rounded-md;
    @apply px-4;
    @apply py-2;
    @apply text-sm;
    @apply font-semibold;
    @apply leading-5;
    @apply text-slate-600;
    @apply shadow-sm;

    @apply border;
    @apply border-gray-400;

    @apply hover:bg-slate-100;
  }

  .flat, .btn-flat {
    @apply font-medium;
    @apply text-blue-1;

    @apply hover:opacity-75;
  }

  .btn-danger {
    @apply rounded-md py-2 px-4;

    @apply text-sm font-semibold leading-5 text-red-600;
    @apply border border-red-600;

    @apply hover:bg-red-600 hover:text-white;
  }

  .upsell-bg {
    background-image: url("./assets/imgs/upsell-bg.jpg");
    @apply bg-cover;
  }

  .upsell-badge-animate path:first-of-type {
    @apply animate-spin;
    @apply origin-center;
    animation-duration: 25s;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}